import { disableScroll, enableScroll } from '@/modules/questionnaire/utils';

const disableScrollOnThisPage = {
  created() {
    disableScroll();
  },
  destroyed() {
    enableScroll();
  }
};

export default disableScrollOnThisPage;
