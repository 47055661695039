import { get, fromPairs } from 'lodash';
import { mapState } from 'vuex';

const mapAISelectedField = fieldName => [
  `${fieldName}AiSelected`,
  state => get(state, ['questionnaire', 'photoAnalysisDiagnostic', fieldName])
];

export default function mapAiSelectedFields(fieldNames) {
  return {
    computed: {
      ...mapState(fromPairs(fieldNames.map(mapAISelectedField)))
    }
  };
}
