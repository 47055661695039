import { intersection } from 'ramda';

const KEYS_FOR_KEYBOARD_NAVIGATION = ['ArrowUp', 'Up', 'ArrowDown', 'Down', 'Shift', 'Tab'];

const Listener = timeSpan => {
  let timer;

  const recentlyPressedKeys = new Set();

  const callback = e => {
    clearTimeout(timer);
    recentlyPressedKeys.add(e.key);

    timer = setTimeout(() => {
      recentlyPressedKeys.clear();
    }, timeSpan);
  };

  return {
    callback,
    recentlyPressedKeys
  };
};

const LISTENERS = {};

const getRecentlyPressedKeysByTimeSpan = timeSpan => {
  let listener = LISTENERS[timeSpan];

  if (!listener) {
    listener = Listener(timeSpan);
    LISTENERS[timeSpan] = listener;

    window.addEventListener('keydown', listener.callback, {
      capture: true
    });
  }

  return listener.recentlyPressedKeys;
};

/** Try to infer whether keyboard navigation was used recently. */
export const isKeyboardNavigationUsedRecently = timeSpan => {
  const recentlyPressedKeys = getRecentlyPressedKeysByTimeSpan(timeSpan);

  return intersection(KEYS_FOR_KEYBOARD_NAVIGATION, Array.from(recentlyPressedKeys)).length > 0;
};
