import { isKeyboardNavigationUsedRecently } from '@/services/keyboardNavigationObserver';

const getQuestion = () => document.querySelector('[data-question]');

export const setFocusOnFirstQuestionForKeyboardUsers = () => {
  if (isKeyboardNavigationUsedRecently(3000)) {
    const questionElement = getQuestion();

    if (questionElement) {
      questionElement.focus();
    }
  }
};
