import { createHelpers } from 'vuex-map-fields';
import { isEmpty } from 'lodash';

import { isKeyboardNavigationUsedRecently } from '@/services/keyboardNavigationObserver';
import { setFocusOnFirstQuestionForKeyboardUsers } from '@/services/questionnaireQuestionAutofocus';

import { types } from '@/modules/questionnaire/store/types';

const { mapFields } = createHelpers({
  getterType: types.getters.GET_FIELD,
  mutationType: types.mutations.SET_FIELD
});

export const createValidator = (validationFunction, errorMessage) => ({
  validate: validationFunction,
  errorMessage
});

export const ERROR_MESSAGE = {
  REQUIRED: 'info.warning.giveAnswer'
};

export const VALIDATOR = {
  REQUIRED: createValidator(value => !!value, ERROR_MESSAGE.REQUIRED),
  REQUIRED_ARRAY: createValidator(value => !isEmpty(value), ERROR_MESSAGE.REQUIRED)
};

export const fieldBuilder = {
  field: fieldName => ({ field: fieldName }),
  requiredField: fieldName => ({ field: fieldName, validators: [VALIDATOR.REQUIRED] }),
  requiredArrayField: fieldName => ({ field: fieldName, validators: [VALIDATOR.REQUIRED_ARRAY] }),
  fieldWithCustomValidation: (fieldName, validators) => ({
    field: fieldName,
    validators
  })
};

// TODO https://jira.andersenlab.com/browse/UNK-6305: investigate the alternatives for
// handling the case with the field "wrinkles" in WrinkleLocations step (this field should not be
// mapped to vuex store but it should be validated). One of the alternatives is to split the mixin
// in two mixins (one for mapping to vuex and another one for validation)
export const convertFieldToLocal = field => ({ ...field, isLocal: true });

export default function makeStep(fields) {
  const storeFieldNames = fields
    .filter(({ isLocal }) => !isLocal)
    .map(({ field: fieldName }) => fieldName);

  const allFieldNames = fields.map(({ field: fieldName }) => fieldName);

  return {
    data() {
      return {
        fieldErrors: {},
        shouldValidateOnFieldChange: false
      };
    },
    computed: {
      ...mapFields(storeFieldNames)
    },
    mounted() {
      setFocusOnFirstQuestionForKeyboardUsers();
    },
    methods: {
      async onFieldChange({ fieldName, value }) {
        this[fieldName] = value;

        if (this.shouldValidateOnFieldChange) {
          await this.updateStepValidity();
        }
      },
      async updateStepValidity() {
        const isValid = await this.validateFields();

        if (isValid) {
          this.$emit('valid');
        }
      },
      async scrollTo(element, offset) {
        const scrollOffset = offset || -20;

        await this.$nextTick();

        this.$scrollTo(element, 800, {
          offset: scrollOffset,
          container: '.questionnaire-page'
        });
      },
      async showPreviousStep() {
        await this.$nextTick();

        this.$emit('previous');
      },
      async showNextStep() {
        if (isKeyboardNavigationUsedRecently(300)) {
          // we do not want to break ux for users
          // who use keyboard for navigation through questionnaire
          return;
        }

        await this.$nextTick();

        this.$emit('next');
      },
      isFieldVisible(/* fieldName */) {
        // Overwrite for each step or leave default implementation if all fields are visible.
        return true;
      },
      validateFields() {
        this.shouldValidateOnFieldChange = true;

        return allFieldNames
          .filter(this.isFieldVisible)
          .map(this.validateField)
          .every(isValid => isValid);
      },
      validateField(fieldName) {
        const currentValue = this[fieldName];
        const { validators } = fields.find(({ field }) => field === fieldName);

        this.fieldErrors = {
          ...this.fieldErrors,
          [fieldName]: []
        };

        if (!validators) {
          return true;
        }

        const { errorMessage } = validators.find(({ validate }) => !validate(currentValue)) || {};

        if (errorMessage) {
          this.fieldErrors[fieldName].push(errorMessage);

          return false;
        }

        return true;
      }
    }
  };
}
