<template>
  <patch-causes-template
    :age="age"
    :first-patch-occurrences="firstPatchOccurrences"
    :patch-causes="patchCauses"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import PatchCausesTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/patch-causes/PatchCausesTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { field, requiredField } = fieldBuilder;

const FIELDS = [
  field('age'),
  field('genotype'),
  field('gender'),
  requiredField('patchCauses'),
  requiredField('firstPatchOccurrences')
];

export default {
  name: 'PatchCauses',
  components: {
    PatchCausesTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    firstPatchOccurrences(newValue, oldValue) {
      if (!oldValue && this.patchCauses.includes('unsure')) {
        this.showNextStep();
      } else {
        this.scrollTo('#patch-causes');
      }
    },
    patchCauses(newValue, oldValue) {
      if (
        this.firstPatchOccurrences &&
        newValue.includes('unsure') &&
        !oldValue.includes('unsure')
      ) {
        this.showNextStep();
      }
    }
  }
};
</script>
