import getInitialQuestionnaireFields from '@/modules/questionnaire/store/getInitialQuestionnaireFields';

const DEFAULT_FIELD_VALUES = getInitialQuestionnaireFields();

const resetStoreValues = {
  methods: {
    resetStoreFieldsByName(fields) {
      fields.forEach(fieldName => {
        this[fieldName] = DEFAULT_FIELD_VALUES[fieldName];
      });
    }
  }
};

export default resetStoreValues;
